<template>
  <div>
  <div>
    <div class="has-background-secondary">
      <div class="container content py-6 mb-6">
        <h1 class="has-text-white my-0">VPN</h1>
      </div>
    </div>

    <div class="container content pb-6">
      <div style="max-width: 1000px;">
        <p>
          VPNs help hide your location and deter websites from spying on you. We highly recommend AmbitVPN because it works anywhere in the world, has the highest standard of post quantum encryption and is very reliable; it even speeds up your internet connection and reduces “throttling” by your Internet Service Provider.
        </p>
        <p>
          There are still lots of websites out there that set cookies on your computer. Cookies are small files which are stored on your computer and are used to re-target you and track what you are doing and seeing on the internet. Plumb doesn’t store cookies on your computer, some the sites you visit will when you click on them. You can also use Plumb Mobile, which prevents cookies from being stored on your mobile device.
        </p>
        <p>
<!--          <external-link style="font-weight:bold" :href="$settings.VPN_AFFILIATE_LINK">Try Ambit VPN now – 14 day free trial</external-link>-->
        </p>
      </div>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: "Vpn"
}
</script>